import React, { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux';

import ContextStore from 'modules/context';
import { tabletMedia } from 'constants';
import CompanyFilter from 'components/CompanyFilter';
import ScheduleSidebar from './ScheduleSidebar';
import ScheduleFilter from './ScheduleFilter';
import FileManagementButton from './FileManagementButton';

const useStyles = createUseStyles({
  scheduleContainer: {
    position: 'relative',
    // top: '64px',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif',
    [tabletMedia]: {
      overflow: 'hidden',
      height: '100%',
      maxWidth: 'calc(100% - 10px)'
    }
  },
});

function Schedule() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, setHeaderTools } = useContext(ContextStore)
  const classes = useStyles()
  const location = useLocation()
  const config = useSelector(state => state.config.data)

  useEffect(() => {
    setHeaderTools(<>
      <ScheduleFilter />
      <CompanyFilter userRight='schedule-view' />
      { config.modules.punchClock && <FileManagementButton /> }
    </>)
    return () => {
      setHeaderTools(null)
    };
  }, []);

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.schedule' })
    }])
    return () => {
    };
  }, [location.pathname]);

  return (
    <div className={classes.scheduleContainer}>
      <ScheduleSidebar />
    </div>
  );
}

export default Schedule;
