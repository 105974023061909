import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import weekday from 'dayjs/plugin/weekday';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss'

import LoadingIndicator from 'components/LoadingIndicator';
import { doctortPunchCount } from 'modules/doctorModule';
import { datesData, leaveTimeRangeCount } from 'modules/uitls';
import { documentId } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import { tabletMedia } from 'constants';
import ScheduleRows from './ScheduleRows';
import ScheduleDoctorRows from './ScheduleDoctorRows';

dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(weekday)

const useStyles = createUseStyles({
  scheduleTable: {
    width: '100%',
    height: '100%',
    position: 'sticky',
    zIndex: 2,
    top: '64px',
    backgroundColor: '#f6f6f7',
    [tabletMedia]: {
      width: '100%',
    }
  },
  scheduleTableLeft: {
    overflow: 'hidden',
    height: 'calc(100vh - 64px - 60px)',
    paddingBottom: '20px',
    [tabletMedia]: {
      width: '100%',
      maxHeight: '100px',
      borderBottom: '1px solid #828a99',
    }
  },
  scheduleTableLeftColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '200px',
    borderRight: '1px solid $table-dark-border',
    position: 'relative',
    fontSize: '14px',
    [tabletMedia]: {
      flexDirection: 'row',
      width: '100%',
      overflowY: 'scroll',
      borderRight: '1px solid #828a99',
    }
  },
  date: {
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    width: '200px',
    padding: '0 20px',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #828a99',
    alignItems: 'center',
    [tabletMedia]: {
      borderLeft: '1px solid #828a99',
      borderBottom: '0px',
      width: '100%',
    }
  },
  dateYear: {
    display: 'block',
    width: '100%',
    color: '#495057',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    padding: '.25rem .5rem',
    fontSize: '.875rem',
    lineHeight: 1.5,
    borderRadius: '.2rem'
  },
  dateMonth: {
    display: 'block',
    width: '100%',
    color: '#495057',
    backgroundColor: '#fff',
    border: '1px solid #ced4da',
    padding: '.25rem .5rem',
    fontSize: '.875rem',
    lineHeight: 1.5,
    borderRadius: '.2rem'
  },
  fieldManagerHeader: {
    flex: 1,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    borderRight: '1px solid #828a99',
    borderBottom: '1px solid #828a99',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.9px',
    textAlign: 'left',
    color: '#8c8c8d'
  },
  employeeTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100px',
    borderBottom: '1px solid #efeff4',
    borderRight: '1px solid #828a99',
    [tabletMedia]: {
      flexDirection: 'column',
      border: '1px solid #828a99',
      minWidth: '100px',
      minHeight: '100px'
    }
  },
  employeeName: {
    padding: '0 10px',
    fontWeight: 500,
    [tabletMedia]: {
      padding: 0,
      fontSize: '10px'
    }
  },
  employeeLeaveCount: {
    width: '110px',
    fontSize: '14px',
    fontweight: 500,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    color: '#8c8c8d',
    marginRight: '5px',
    marginLeft: 'auto',
    [tabletMedia]: {
      fontSize: '12px',
      textAlign: 'center'
    }
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

function ScheduleTable({ currentUser, userRight, staffType, departmentId, policyMapping, isTablet, currentCompany }) {
  const classes = useStyles();
  const [weeklyPolicyMapping, setWeeklyPolicyMapping] = useState({})
  const [weeklyLeaves, setWeeklyLeaves] = useState([])
  const [punchClock, setPunchClock] = useState(null)
  const [leaves, setLeaves] = useState([])
  const [shifts, setShifts] = useState([])
  const [comments, setComments] = useState([])
  const [overtimes, setOvertimes] = useState([])
  const [punchClockExceptions, setPunchClockExceptions] = useState([])
  const [punchClockRevise, setPunchClockRevise] = useState(null)
  const [staffPartTime, setStaffPartTime] = useState({});
  const [leaveBalance, setLeaveBalance] = useState({});
  const [viewingStaff, setViewingStaff] = useState(null)
  const [doctorShift, setDoctorShift] = useState({})
  const [doctorSpecial, setDoctorSpecial] = useState({})
  const [doctorLeave, setDoctorLeave] = useState({})
  const [weeklyHistory, setWeeklyHistory] = useState({})
  const [userProfileMapping, setUserProfileMapping] = useState({})
  const StaffTableRef = useRef()
  const [tableData, setTableData] = useState({
    month: dayjs().format('M'),
    year: dayjs().format('YYYY')
  })

  const _userMapping = useSelector(state => state.users.data)
  const userMapping = Object.keys(_userMapping).reduce((acc, cur) => {
    if (userProfileMapping[cur]) {
      acc[cur] = { ..._userMapping[cur], ...userProfileMapping[cur] }
    } else {
      acc[cur] = { ..._userMapping[cur] }
    }

    return acc
  }, {})

  const users = useSelector(state => state.users.ordered)
    .filter(u => (u.active || (!u.active && dayjs(u.endOn).format('YYYY-MM') >= `${tableData.year}-${tableData.month.padStart(2, '0')}`)) && !u.developer && u.department === departmentId)
    .filter(u => u.company?.includes(currentCompany))

  const config = useSelector(state => state.config.data)
  const occupations = Object.keys(config.occupation || {}).map(i => config.occupation[i])
  const dates = datesData(tableData.year, tableData.month)
  const startDateM = dayjs(`${tableData.year}${tableData.month}`).isBefore(dayjs()) ? dayjs(dates[0]).format('YYYY-MM-DD') : `${dayjs().format('YYYY-MM')}-01`
  const endDateM = dayjs(dates[dates.length - 1]).format('YYYY-MM-DD')
  const sortData = users.sort((a, b) => {
    let newMapping = {}

    occupations.forEach((o, index) => {
      newMapping[o.id] = { ...o, order: index }
    })

    const ao = newMapping[a.occupation]?.order ?? Object.keys(newMapping).length
    const bo = newMapping[b.occupation]?.order ?? Object.keys(newMapping).length
    return ao - bo
  }).filter(u => {
    if (userRight.hasUserRightForVendor('schedule-view', currentCompany)) {
      return u
    } else {
      return u.id === currentUser.key
    }
  })

  const staffKey = []
  const staffWorktime = {}
  const staffWeekly = {}

  const monthMapping = {
    Apr: 4,
    Aug: 8,
    Dec: 12,
    Feb: 2,
    Jan: 1,
    Jul: 7,
    Jun: 6,
    Mar: 3,
    May: 5,
    Nov: 11,
    Oct: 10,
    Sep: 9
  }

  for (const o of sortData) {
    staffKey.push(o.id)
    staffWorktime[o.id] = o.worktimeType === 'shift' ? shifts.reduce((acc, cur) => {
      acc[`${cur.date}_${dayjs(cur.date).format('ddd')}`] = {
        startTime: cur.startTime,
        endTime: cur.endTime,
      }
      return acc
    }, {}) : policyMapping[o.worktimePolicies]?.worktime ?? {}
    if (weeklyPolicyMapping[tableData.year]) {
      const policies = weeklyPolicyMapping[tableData.year]

      if (o.worktimeType !== 'fixedShift') {
        staffWeekly[o.id] = Object.keys(policies.earned).reduce((acc, cur) => {
          const newMonth = String(monthMapping[cur]).padStart(2, '0')
          const newDate = `${policies.year}-${newMonth}`

          acc[newDate] = policies.earned[cur]
          return acc
        }, {})
      }
    }
  }

  const yearMonthStr = `${tableData.year}${String(tableData.month).padStart(2, '0')}`

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebaseV8().collection('scheduleComments')
      .where('date', '>=', startDateM).where('date', '<=', endDateM)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setComments(data.filter(c => c.source === currentCompany))
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM, currentCompany]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('punchClockExceptions').where('date', '>=', startDateM).where('date', '<=', endDateM).onSnapshot(snapshot => {
      const punchClockException = []
      snapshot.forEach(doc => {
        punchClockException.push({ ...doc.data(), id: doc.id })
      });
      setPunchClockExceptions(punchClockException.filter(p => p.source.includes(currentCompany)))
    }, err => { })

    return () => unsubscribe()
  }, [startDateM, endDateM, currentCompany]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebaseV8().collection('leaveList')
      .where('status', '==', 'done')
      .where('monthIndex', 'array-contains', yearMonthStr)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setLeaves(data)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [yearMonthStr]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebaseV8().collection('weeklyLeavesHistory').onSnapshot(snapshot => {
      const data = {}
      snapshot.forEach(doc => {
        data[doc.id] = { ...doc.data() }
      });
      setWeeklyHistory(data)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType === 'doctor' ? firebaseV8().collection('doctorShifts').where('repeats', '!=', 'no')
      .onSnapshot(snapshot => {
        const shifts = {}
        snapshot.forEach(doc => {
          const data = doc.data()
          shifts[doc.id] = { ...data };
        })

        const newShifts = Object.keys(shifts).reduce((acc, cur) => {
          if (shifts[cur].source === currentCompany) {
            acc[cur] = { ...shifts[cur] }
          }

          return acc
        }, {})
        setDoctorShift(newShifts)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [currentCompany]);

  useEffect(() => {
    const unsubscribe = staffType === 'doctor' ? firebaseV8().collection('doctorShifts')
      .where('firstDate', '>=', startDateM).where('firstDate', '<=', endDateM).where('repeats', '==', 'no')
      .onSnapshot(snapshot => {
        const shifts = {}
        snapshot.forEach(doc => {
          const data = doc.data()
          shifts[doc.id] = { ...data };
        })

        const newShifts = Object.keys(shifts).reduce((acc, cur) => {
          if (shifts[cur].source === currentCompany) {
            acc[cur] = { ...shifts[cur] }
          }

          return acc
        }, {})
        setDoctorSpecial(newShifts)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM, currentCompany]);

  useEffect(() => {
    const unsubscribe = staffType === 'doctor' ? firebaseV8().collection('doctorLeaves')
      .where('firstDate', '>=', startDateM).where('firstDate', '<=', endDateM)
      .onSnapshot(snapshot => {
        const leaves = {}
        snapshot.forEach(doc => {
          const data = doc.data()
          leaves[doc.id] = { ...data };
        })
        const newLeaves = Object.keys(leaves).reduce((acc, cur) => {
          if (leaves[cur].source === currentCompany) {
            acc[cur] = { ...leaves[cur] }
          }

          return acc
        }, {})
        setDoctorLeave(newLeaves)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM, currentCompany]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('overtimes')
      .where('startDate', '>=', startDateM).where('startDate', '<=', endDateM)
      .where('status', '==', 'done').onSnapshot(snapshot => {
        const overtimes = []
        snapshot.forEach(doc => {
          overtimes.push({ ...doc.data(), id: doc.id })
        });

        setOvertimes(overtimes)
      }, err => { })

    return () => unsubscribe()
  }, [startDateM, endDateM]);


  useEffect(() => {
    const unsubscribe = firebaseV8().collection('punchClock')
      .where(documentId(), '>=', startDateM).where(documentId(), '<=', endDateM)
      .onSnapshot(snapshot => {
        const data = {}
        const reviseData = {}
        snapshot.forEach(doc => {
          data[doc.id] = { ...doc.data().record }
          reviseData[doc.id] = { ...doc.data().revise }
        });

        setPunchClock(data)
        setPunchClockRevise(reviseData)
      }, err => { })
    return () => unsubscribe()
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebaseV8().collection('weeklyLeaves')
      .where('date', '>=', startDateM).where('date', '<=', endDateM)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setWeeklyLeaves(data)
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebaseV8().collection('shifts')
      .where('date', '>=', startDateM).where('date', '<=', endDateM)
      .onSnapshot(snapshot => {
        const data = []
        snapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        });
        setShifts(data.filter(s => s.source === currentCompany))
      }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM, currentCompany]);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebaseV8().collection('weeklyPolicies').onSnapshot(snapshot => {
      const data = {}
      snapshot.forEach(doc => {
        const w = doc.data()
        data[w.year] = { id: doc.id, ...w }
      });
      setWeeklyPolicyMapping(data)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, []);

  useEffect(() => {
    const unsubscribe = staffType !== 'doctor' ? firebaseV8().collection('leaveBalances').onSnapshot(snapshot => {
      const data = []
      snapshot.forEach(doc => {
        data[doc.id] = doc.data()
      });
      setLeaveBalance(data)
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, [startDateM, endDateM]);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('userSalarys')
      .where('partTime', '==', true).onSnapshot(snapshot => {
        let newData = {}
        snapshot.forEach(doc => {
          newData[doc.id] = true
        })

        setStaffPartTime(newData)
      }, err => { })

    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('userProfiles').onSnapshot(snapshot => {
      let data = {}
      snapshot.forEach(doc => {
        data[doc.id] = { ...doc.data() }
      })

      setUserProfileMapping(data)
    }, err => { })

    return () => unsubscribe()
  }, []);

  if (!punchClock || !punchClockRevise) {
    return <LoadingIndicator />
  }

  const months = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const years = []
  for (const year of [-2, -1, 0, 1, 2]) {
    if (year === 0) {
      years.push(dayjs().format('YYYY'))
    } else if (year < 0) {
      years.push(dayjs().subtract(Math.abs(year), 'years').format('YYYY'))
    } else {
      years.push(dayjs().add(year, 'years').format('YYYY'))
    }
  }

  function updateScheduleData(name, value) {
    const newData = { ...tableData }
    newData[name] = value
    setTableData(newData)
  }

  function onScrollRow(scrollTop) {
    requestAnimationFrame(() => {
      StaffTableRef.current.scrollTop = scrollTop
    });
  }

  const punchClockReviseCount = (key) => {
    let count = 0;
    for (const d of dates) {
      const date = dayjs(d).format('YYYY-MM-DD')
      const newPunchClockRevise = punchClockRevise[date]
      if (newPunchClockRevise) {
        for (const p of Object.keys(newPunchClockRevise)) {

          if (p === key && newPunchClockRevise[p].length > 0) {
            count += newPunchClockRevise[p].length
          }
        }
      }
    }

    return count
  }

  function doctorPunchClock(doctorId) {
    let doctorMapping = {}
    let doctorPunchClock = {}
    let doctorShiftLeave = {}
    for (const d of dates) {
      const date = dayjs(d).format('YYYY-MM-DD')
      const newPunchClock = punchClock[date]
      const newPunchClockRevise = punchClockRevise[date]
      if (newPunchClock) {
        for (const p of Object.keys(newPunchClock)) {
          if (p === doctorId) {
            doctorPunchClock[date] = { punchClock: newPunchClock[p], ...doctorPunchClock[date] }
          }
        }
      }
      if (newPunchClockRevise) {
        for (const p of Object.keys(newPunchClockRevise)) {
          if (p === doctorId) {
            doctorPunchClock[date] = { punchClockRevise: newPunchClockRevise[p], ...doctorPunchClock[date] }
          }
        }
      }

      doctorShiftLeave[date] = doctortPunchCount(date, doctorId, doctorShift, doctorSpecial, doctorLeave)
      doctorMapping[date] = {
        ...doctorPunchClock[date],
        ...doctorShiftLeave[date],
      }
    }


    let count = 0;
    let specialCount = 0
    let leaveCount = 0

    for (const key of Object.keys(doctorMapping)) {
      let shift = doctorMapping[key].shifts
      let special = doctorMapping[key].specials
      let leave = doctorMapping[key].leaves
      let fullPunchClock = doctorMapping[key].punchClock && sortPunchClockData(key, doctorMapping[key].punchClock.concat(doctorMapping[key].punchClockRevise || []))

      if (shift && fullPunchClock) {
        let punchInTime = fullPunchClock[0];
        let punchOutTime = fullPunchClock[fullPunchClock.length - 1];
        let punchInTimeM = dayjs(key + ' ' + punchInTime, 'YYYY-MM-DD HH:mm');
        let punchOutTimeM = dayjs(key + ' ' + punchOutTime, 'YYYY-MM-DD HH:mm');
        let diff = punchOutTimeM.diff(punchInTimeM, 'minutes')
        count += diff;
      }

      if (special.length > 0 && fullPunchClock) {
        for (const s of special) {
          let punchInTime = s.startTime;
          let punchOutTime = s.endTime;
          let punchInTimeM = dayjs(key + ' ' + punchInTime, 'YYYY-MM-DD HH:mm');
          let punchOutTimeM = dayjs(key + ' ' + punchOutTime, 'YYYY-MM-DD HH:mm');
          let diff = punchOutTimeM.diff(punchInTimeM, 'minutes')
          specialCount += diff;
        }
      }

      if (leave && (shift || special) && fullPunchClock) {
        let leaveInTime = leave.startTime;
        let leaveOutTime = leave.endTime;
        let leaveInTimeM = dayjs(key + ' ' + leaveInTime, 'YYYY-MM-DD HH:mm');
        let leaveOutTimeM = dayjs(key + ' ' + leaveOutTime, 'YYYY-MM-DD HH:mm');
        let diff = leaveOutTimeM.diff(leaveInTimeM, 'minutes')

        leaveCount += diff
      }
    }

    const totalShiftCount = count + specialCount

    const resultH = ((totalShiftCount - leaveCount) - ((totalShiftCount - leaveCount) % 60)) / 60
    const resultM = (totalShiftCount - leaveCount) % 60

    const resultHStr = resultH > 0 ? `${resultH}` : '0'
    const resultMStr = resultM > 0 ? `${resultM}` : '0'
    return `${resultHStr}h ${resultMStr}m`
  }

  const sortPunchClockData = (date, punchClockData) => {
    let dateM = dayjs(date, 'YYYY-MM-DD');
    return Array.from(new Set(punchClockData.sort((a, b) => {
      const timeAM = dayjs(dateM.format('YYYY-MM-DD') + ' ' + a, 'YYYY-MM-DD HH:mm');
      const timeBM = dayjs(dateM.format('YYYY-MM-DD') + ' ' + b, 'YYYY-MM-DD HH:mm');
      if (timeAM.isBefore(timeBM)) {
        return -1;
      } else if (timeAM.isBefore(timeBM)) {
        return 1;
      } else {
        return 0;
      }
    })));
  }

  function weeklyEarnedCount(key, total) {
    const currentDateM = dayjs().format('YYYY-MM')
    const monthIndex = [];
    const endM = dayjs(`${tableData.year}-${tableData.month}`).format('YYYY-MM')
    const weeklyData = weeklyLeaves ? weeklyLeaves.filter(m => m.staff === key && dayjs(m.date).isSameOrAfter(dayjs(`${tableData.year}-${tableData.month}`))) : []

    let currentMonth = dayjs();
    let earned = 0;
    let monthWeekly = 0;
    let weeklyCount = 0
    let weekBalance = leaveBalance[key] ? leaveBalance[key]['weekly_leave'].balance : 0
    for (; ;) {
      monthIndex.push(currentMonth.format('YYYY-MM'))
      currentMonth = currentMonth.add(1, 'months')
      if (currentMonth.format('YYYY-MM') > endM) {
        break;
      }
    }

    for (const month of monthIndex) {
      if (staffWeekly[key] && staffWeekly[key][month]) {
        monthWeekly += (Number(staffWeekly[key][month]) * 8)
      }
    }

    for (const data of weeklyData) {
      const newData = {
        startDate: data.date,
        endDate: data.date,
        startHour: data.startTime.split(':')[0],
        startMinute: data.startTime.split(':')[1],
        endHour: data.endTime.split(':')[0],
        endMinute: data.endTime.split(':')[1],
        leaveType: 'weekly_leave'
      }

      const pce = punchClockExceptions.reduce((acc, cur) => {
        if (cur.date === data.date) {
          acc[cur.date] = { ...cur }
        }

        return acc
      }, {})

      let weeklyData = leaveTimeRangeCount(newData, userMapping[key].worktimeType, staffWorktime[key], pce, userMapping[key])

      if (weeklyData) {
        weeklyCount += (weeklyData.day * 8) + weeklyData.hour
      } else {
        const minutes = dayjs(`${data.date} ${data.endTime}`).diff(dayjs(`${data.date} ${data.startTime}`), 'minutes')
        let hours = (minutes - (minutes % 60)) / 60
        const minute = minutes % 60

        if (minute !== 0) {
          if (minute <= 30) {
            hours += 0.5
          } else {
            hours += 1
          }
        }

        weeklyCount += Math.min(hours, 8)
      }
    }

    if (endM >= currentDateM) {
      earned = (monthWeekly - weeklyCount) + weekBalance
    } else {
      const weekly = weeklyHistory[`${endM}`] ? weeklyHistory[`${endM}`][key] : null
      earned = weekly ? weekly.balance : 0
    }

    if (total) {
      return monthWeekly + weekBalance
    } else {
      return earned
    }
  }

  const leaveCounts = {
    'overtime_leave': 0,
    'annual_leave': 0,
  };

  const rowData = staffType === 'doctor' ? {
    punchClock,
    punchClockRevise,
    punchClockExceptions,
  } : {
    punchClock,
    punchClockRevise,
    punchClockExceptions,
    weeklyLeaves,
    leaves,
    comments,
    overtimes,
    shifts
  }

  const doctorRow = {
    doctorShift,
    doctorSpecial,
    doctorLeave
  }

  return (
    <div className={classes.scheduleTable}>
      <div className={classes.date}>
        <div>
          <select onChange={(e) => updateScheduleData('year', e.target.value)} value={tableData.year} className={classes.dateYear}>
            {years.map(year => {
              return <option key={year} value={year}>{year}</option>
            })}
          </select>
        </div>
        <div style={{ marginLeft: '10px' }}>
          <select onChange={(e) => updateScheduleData('month', e.target.value)} value={tableData.month} className={classes.dateMonth}>
            {months.map(month => {
              return <option key={month} value={month}>{month}</option>
            })}
          </select>
        </div>
      </div>
      <div className={classes.scheduleTableLeft} ref={StaffTableRef}>
        <div className={classes.scheduleTableLeftColumn}>
          {staffKey.map((key, idx) => {
            leaveCounts.overtime_leave = leaveBalance[key] ? leaveBalance[key].overtime_leave.balance : 0;
            leaveCounts.annual_leave = leaveBalance[key] ? leaveBalance[key].annual_leave.balance : 0;
            if (userMapping[key].worktimeType !== 'fixedShift' && userMapping[key].department !== 'doctor') { leaveCounts.weekly_leave = weeklyEarnedCount(key, false) }
            const punchClockReviseCounts = punchClockReviseCount(key)
            return <div
              key={key}
              className={classes.employeeTable}
              onClick={() => isTablet ? setViewingStaff(key) : {}}
              style={{
                borderBottom: isTablet && viewingStaff === key ? '4px solid #3f51b5' : '',
                color: isTablet && viewingStaff === key ? '#3f51b5' : ''
              }}
            >
              <div className={classes.employeeName}>{userMapping[key].displayName} </div>
              {config.modules.punchClock &&
                <div
                  style={{
                    color: isTablet && viewingStaff === key ? '#3f51b5' : ''
                  }}
                  className={classes.employeeLeaveCount}
                >
                  {staffPartTime[key] && <div>{'PT'}</div>}
                  {(userMapping[key].worktimeType !== 'fixedShift') && userMapping[key].department !== 'doctor' &&
                  <div
                    style={{ color: leaveCounts.weekly_leave < 0 && 'red' }}
                  >
                    {!staffPartTime[key] && `本休: ${(leaveCounts.weekly_leave - (leaveCounts.weekly_leave % 8)) / 8}D ${leaveCounts.weekly_leave % 8}h`}
                  </div>
                  }
                  {staffType !== 'doctor' ?
                    <>
                      <div style={{ color: leaveCounts.overtime_leave < 0 && 'red' }}>
                        {!staffPartTime[key] && `補休: ${(leaveCounts.overtime_leave - (leaveCounts.overtime_leave % 8)) / 8}D ${leaveCounts.overtime_leave % 8}h`}
                      </div>
                      <div>{!staffPartTime[key] && `特休: ${(leaveCounts.annual_leave - (leaveCounts.annual_leave % 8)) / 8}D ${leaveCounts.annual_leave % 8}h`}</div>
                    </> : <div>{`總時數: ${doctorPunchClock(key)}`}</div>
                  }
                  {!staffPartTime[key] && <div style={{ color: punchClockReviseCounts > 0 && 'blue' }}>
                    {`忘刷卡: ${punchClockReviseCounts > 0 ? punchClockReviseCounts : 0}`}
                  </div>}
                </div>
              }
            </div>
          })}
        </div>
      </div>
      {staffType === 'doctor' ?
        <ScheduleDoctorRows
          currentUser={currentUser}
          month={tableData.month}
          year={tableData.year}
          staffData={userMapping}
          staffKey={staffKey}
          userRight={userRight}
          viewingStaff={viewingStaff}
          isTablet={isTablet}
          onScrollRow={onScrollRow}
          {...doctorRow}
          {...rowData}
        /> :
        <ScheduleRows
          currentUser={currentUser}
          staffType={staffType}
          month={tableData.month}
          year={tableData.year}
          staffData={userMapping}
          staffKey={staffKey}
          staffWorktime={staffWorktime}
          userRight={userRight}
          viewingStaff={viewingStaff}
          isTablet={isTablet}
          weeklyEarnedCount={weeklyEarnedCount}
          onScrollRow={onScrollRow}
          {...rowData}
        />
      }
    </div>
  );
}

ScheduleTable.propTypes = {
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    isManagement: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
  }),
  departmentId: PropTypes.string.isRequired,
  staffType: PropTypes.string.isRequired,
  policyMapping: PropTypes.object,
  userRight: PropTypes.object.isRequired,
  isTablet: PropTypes.bool.isRequired,
  currentCompany: PropTypes.string.isRequired
};

export default ScheduleTable;
