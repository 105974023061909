import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import MaskInput from 'components/MaskInput';
import DatePickerField from 'components/DatePickerField';
import ButtonProgress from 'components/ButtonProgress';
import ContextStore from 'modules/context';
import { callFunction } from 'modules/firebase';
import { firebaseV8 } from 'modules/firebaseV8';
import { useDepartments } from 'hooks/departments';
import { useModuleMapping } from 'hooks/modules';
import ApplicationSent from 'components/ApplicationSent';
import AlertDialog from 'components/AlertDialog';

const _fieldSelectMapping = {
  gender: ['male', 'female'],
  position: ['management', 'employee'],
  worktimeType: ['shift', 'shiftBased', 'fixedShift']
}

const DividerText = styled('div')(() => ({
  position: 'absolute',
  top: '-20px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const UnderLine = styled('div')(() => ({
  position: 'absolute',
  top: '8px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function EditStaffPage() {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs } = useContext(ContextStore)
  const { staffId } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [reviewPolicies, setReviewPolicies] = useState([]);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [worktimePolicies, setWorktimePolicies] = useState([])
  const vendorMapping = useSelector(state => state.vendors.data)
  const vendors = useSelector(state => state.vendors.ordered)

  const config = useSelector(state => state.config.data)
  const departments = useDepartments()
  const moduleMapping = useModuleMapping()
  const occupations = Object.keys(config.occupation || {}).filter(i => config.occupation[i].active).map(i => config.occupation[i])
  const appointmentType = Object.keys(config.appointmentType || {}).filter(i => config.appointmentType[i].active).map(i => config.appointmentType[i])
  const defaultData = {
    active: true,
    name: '',
    gender: '',
    employeeId: '',
    email: '',
    department: '',
    position: '',
    reviewLeavePolicy: '',
    reviewOvertimePolicy: '',
    reviewPunchClockPolicy: '',
    worktimeType: '',
    worktimePolicies: '',
    onBoardingDate: dayjs().format('YYYY-MM-DD'),
    appointmentType: '',
    smallStamp: '',
    bigStamp: '',
    certificateStamp: '',
    avatarUrl: '',
    occupation: '',
    company: [],
    unpaidLeave: []
  }
  const defaultProfileData = {
    phone: '',
    address: '',
    birthDate: '',
    identityCardNumber: '',
    emergencyName: '',
    emergencyPhone: '',
    note: '',
    fullTime: '',
    bankAccount: ''
  }
  const reviewPolicyMapping = reviewPolicies.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})
  const worktimePolicyMapping = worktimePolicies.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})

  const users = useSelector(state => state.users.ordered).filter(u => u.active && !u.developer)
  const limits = config.limits // 判斷使用者人數
  const [userCountErr, setUserCountErr] = useState(false)

  const [data, setData] = useState(defaultData)
  const [currentActive, setCurrentActive] = useState(true)
  const [profileData, setProfileData] = useState(defaultProfileData)
  let staffData = {}

  const fieldSelectMapping = {
    ..._fieldSelectMapping,
    department: departments,
    occupation: occupations,
    appointmentType: appointmentType,
    company: vendors.filter(v => v.active && v.internal).reduce((acc, cur) => { acc.push({ id: cur.id, name: cur.name }); return acc }, [])
  }

  useEffect(() => {
    const breadcrumbs = [{
      link: '/staffManagement/staff',
      text: formatMessage({ id: 'sideMenu.staffManagement.staff' })
    }]
    if (staffId === 'new') {
      breadcrumbs.push({ text: formatMessage({ id: 'staff.dialog.title.add' }) })
    } else {
      breadcrumbs.push({ text: formatMessage({ id: 'staff.dialog.title.edit' }) })
    }
    setBreadcrumbs(breadcrumbs)
    return () => {
    };
  }, [location.pathname,]);

  useEffect(() => {
    const unsubscribe = staffId !== 'new' ? firebaseV8().collection('users').doc(staffId).onSnapshot(snapshot => {
      const user = formatData(snapshot.data())

      setCurrentActive(user.active)
      setData(staffData => ({
        ...staffData,
        ...user
      }))
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, []);

  useEffect(() => {
    const onSnapshot = snapshot => {
      const reviewPolicies = []
      snapshot.forEach(doc => {
        reviewPolicies.push({ ...doc.data(), id: doc.id })
      });
      setReviewPolicies(reviewPolicies)
    }
    const unsubscribe = firebaseV8().collection('reviewPolicies').onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseV8().collection('worktimePolicies').onSnapshot(snapshot => {
      const policies = []
      snapshot.forEach(doc => {
        policies.push({ id: doc.id, ...doc.data() })
      })
      setWorktimePolicies(policies)
    }, err => { })
    return () => unsubscribe()
  }, []);

  useEffect(() => {
    const unsubscribe = staffId !== 'new' ? firebaseV8().collection('userProfiles').doc(staffId).onSnapshot(snapshot => {
      setProfileData(data => ({
        ...data,
        ...snapshot.data()
      }))
    }, err => { }) : null

    return () => { if (unsubscribe) unsubscribe() }
  }, []);

  if (!data || !profileData) {
    return ('Loading...')
  } else {
    staffData = { ...data, ...profileData }
  }

  function formatData(data) {
    let newData = data
    newData.name = newData.displayName
    newData.position = newData.isManagement ? 'management' : 'employee'
    newData.appointmentType = newData.appointmentType ? newData.appointmentType : ''

    return newData
  }

  function updateData(field, value) {
    let newValue = value

    let err = validateField(field, value)

    if (['fullTime', 'birthDate'].includes(field.name)) {
      if (!value) {
        newValue = ''
      }
    }

    let newData = {}
    if (['phone', 'address', 'birthDate', 'identityCardNumber', 'emergencyName', 'emergencyPhone', 'note', 'fullTime', 'bankAccount'].includes(field.name)) {
      newData = { ...profileData, [field.name]: newValue, [`${field.name}_err`]: err }
      setProfileData(newData)
    } else {
      newData = { ...staffData, [field.name]: newValue, [`${field.name}_err`]: err }
      setData(newData)
    }
  }

  function updateUnpaidLeaveData(field, value) {
    let newValue = value
    let newData = data.unpaidLeave

    newData[field.index] = { ...newData[field.index], [field.name]: newValue }

    setData({ ...data, unpaidLeave: newData })
  }

  function onPhoneChanged(field, value) {
    updateData({ name: `${field.name}` }, value);
  }

  function validateField(field, value) {
    if (field.required && value === '') {
      return formatMessage({ id: 'form.field.isRequired' })
    }

    return ''
  }

  const numberRule = /[^0-9]/g
  const _fields = [
    { name: 'name', sm: 6, required: true, order: 0 },
    { name: 'gender', sm: 6, roots: true, select: true, required: true, order: 1 },
    { name: 'employeeId', sm: 6, required: true, order: 2 },
    { name: 'email', sm: 6, required: true, order: 3 },
    { name: 'typeline', type: '-', order: 4 },
    { name: 'department', sm: 6, select: true, required: true, order: 5 },

    { name: 'dateline', type: '-', order: 17 },
    { name: 'onBoardingDate', sm: 6, md: 3, required: true, type: 'time', order: 18 },
    { name: 'company', sm: 6, md: 6, required: true, order: 19 },
    // { name: 'fullTime', sm: 6, md: 3, type: 'time', order: 19 },
    { name: 'unpaidLeave', type: '-', order: 20 },


    { name: 'profileLine', type: '-', order: 22 },
    { name: 'phone', type: 'number-text', sm: 6, md: 3, allowCharacter: numberRule, mask: '9999-999-999', order: 23 },
    { name: 'birthDate', sm: 6, md: 3, type: 'time', order: 24 },
    { name: 'identityCardNumber', sm: 6, md: 3, order: 25 },
    { name: 'bankAccount', type: 'number-text', sm: 6, md: 3, allowCharacter: numberRule, mask: '99999999999999', order: 26 },
    { name: 'address', multiline: true, sm: 12, md: 6, order: 27 },
    { name: 'note', multiline: true, sm: 12, md: 6, order: 28 },
    { name: 'emergencyContacts', type: '-', order: 29 },
    { name: 'emergencyName', sm: 6, md: 3, order: 30 },
    { name: 'emergencyPhone', type: 'number-text', sm: 6, md: 3, allowCharacter: numberRule, mask: '9999-999-999', order: 31 },
  ]

  if (!data.active) {
    _fields.push({ name: 'endOn', sm: 6, multiline: false, md: 3, required: true, order: 19, type: 'time' })
  }

  if (data.unpaidLeave.length > 0) {
    for (const i in data.unpaidLeave) {
      _fields.push({ name: 'startDate', sm: 6, multiline: false, md: 3, required: true, order: 21, type: 'time-u', index: i })
      _fields.push({ name: 'endDate', sm: 6, multiline: false, md: 3, required: true, order: 21, type: 'time-u', index: i })
      _fields.push({ name: 'note', type: 'text-u', multiline: true, sm: 12, md: 6, order: 21, index: i })
    }
  }

  if (staffData.department !== 'doctor') {
    _fields.push({ name: 'position', sm: 6, roots: true, select: true, required: true, order: 7 })
    if (moduleMapping['leaveOvertime'] || moduleMapping['punchClock']) {
      _fields.push({ name: 'reviewPolicy', type: '-', order: 9 })
    }
    if (moduleMapping['leaveOvertime']) {
      _fields.push({ name: 'reviewLeavePolicy', sm: 6, select: true, required: true, order: 10 })
      _fields.push({ name: 'reviewOvertimePolicy', sm: 6, select: true, required: true, order: 11 })
    }
    if (moduleMapping['punchClock']) {
      _fields.push({ name: 'reviewPunchClockPolicy', sm: 6, select: true, required: true, order: 12 })
    }
    if (moduleMapping['schedule']) {
      _fields.push({ name: 'worktimePolicy', type: '-', order: 13 })
      _fields.push({ name: 'worktimeType', sm: 6, roots: true, select: true, required: true, order: 14 })

      if (staffData.worktimeType !== 'shift') {
        _fields.push({ name: 'worktimePolicies', sm: 6, select: true, required: true, order: 15 })
      }
    }
  }

  if (staffData.department === 'doctor') {
    if (moduleMapping['appointment']) {
      _fields.push({ name: 'appointmentType', sm: 6, select: true, multiline: false, required: true, md: 3, order: 8 })
    }
    _fields.push({ name: 'occupation', sm: 6, select: true, multiline: false, md: 3, order: 6 })
  } else {
    _fields.push({ name: 'occupation', sm: 6, select: true, multiline: false, md: 3, order: 6 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  function newContact() {
    let currentData = [...data.unpaidLeave]

    currentData.push({
      startDate: dayjs(),
      endDate: dayjs()
    })

    setData({ ...data, unpaidLeave: currentData })
  }

  function createField(field) {
    let newValue = staffData[field.name]
    let labelText = ''
    let selectFields;

    if (['time-u', 'text-u'].includes(field.type)) {
      newValue = data.unpaidLeave[field.index][field.name]
    }

    if (field.type === '-') {
      if (['emergencyContacts', 'unpaidLeave'].includes(field.name)) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative', mt: 1 }}>
          <Divider />
          {field.name === 'emergencyContacts' ? <UnderLine>
            <Typography color="textSecondary" variant="caption">{formatMessage({ id: `staff.profile.${field.name}` })}</Typography>
          </UnderLine> : <DividerText style={{ top: '-10px' }}>
            <Typography color="textSecondary" variant="caption">{formatMessage({ id: `staff.profile.${field.name}` })}</Typography>
            <Tooltip title={formatMessage({ id: 'button.add' })}>
              <IconButton onClick={() => newContact()} size="large">
                <MoreTimeIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </DividerText>}
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}><Divider sx={{ mt: 1 }} /></Grid>
      }
    } else if (['time', 'time-u'].includes(field.type)) {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })

      return <Grid item key={field.name === 'time' ? field.name : `${field.name}${field.index}`} xs={12} sm={field.sm} md={field.md} sx={{ mt: '3px' }}>
        <DatePickerField
          fullWidth
          closeToolbar={field.name !== 'birthDate' ? false : true}
          margin="dense"
          required={field.required}
          label={labelText}
          value={newValue !== '' ? dayjs(newValue) : null}
          onChange={date => field.type === 'time' ? updateData({ name: `${field.name}` }, date) : updateUnpaidLeaveData(field, date)}
          invalidDateMessage={formatMessage({ id: 'form.date.formatError' })}
        />
      </Grid>
    } else if (field.type === 'number-text') {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <MaskInput
          mask={field.mask}
          maskChar=" "
          onChange={e => { onPhoneChanged(field, e.target.value) }}
          value={staffData[field.name]}
          label={labelText}
          helperText={staffData[`${field.name}_err`]}
          margin="dense" // TODO: CHECK THIS
        />
      </Grid>
    }

    if (field.roots) {
      labelText = formatMessage({ id: `staff.profile.${field.name}.roots` })
    } else {
      labelText = formatMessage({ id: `staff.profile.${field.name}` })
    }

    if (['gender', 'department', 'position', 'appointmentType', 'occupation', 'worktimeType', 'company'].includes(field.name)) {
      selectFields = fieldSelectMapping[field.name]
    } else if (field.name.startsWith('review') && field.name.endsWith('Policy')) {
      selectFields = reviewPolicies.map(i => i.id)
    } else if (field.name === 'worktimePolicies') {
      selectFields = worktimePolicies.map(i => i.id)
    }

    if (field.name === 'company') {
      return <Grid item key={field.order} xs={12} sm={field.sm} md={field.md} sx={{ mt: '8px' }}>
        <FormControl size="small" fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">{labelText}</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            value={staffData[field.name]}
            multiple
            onChange={e => updateData({ name: `${field.name}` }, e.target.value)}
            input={<OutlinedInput label={labelText} />}
            renderValue={(selected) => selected.map(s => vendorMapping[s].name).join(', ')}
          >
            {selectFields.map(select => {
              return < MenuItem key={select.id} value={select.id} >
                <Checkbox checked={staffData[field.name].includes(select.id)} />
                <ListItemText primary={select.name} />
              </MenuItem>
            })}
          </Select>
          <FormHelperText id="component-error-text">{data[`${field.name}_err`]}</FormHelperText>
        </FormControl>
      </Grid>
    }

    return <Grid item key={field.type === 'text-u' ? field.name + field.index : field.order} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        required={field.required}
        fullWidth
        disabled={field.disabled}
        select={field.select ? field.select : null}
        size="small"
        margin="dense"
        variant="outlined"
        onChange={e => field.type === 'text-u'? updateUnpaidLeaveData(field, e.target.value) : updateData({ name: `${field.name}` }, e.target.value)}
        error={staffData[`${field.name}_err`] ? true : false}
        helperText={staffData[`${field.name}_err`]}
      >
        {
          field.select && selectFields.map((selects, idx) => {
            if (field.name.startsWith('review') && field.name.endsWith('Policy')) {
              return <MenuItem key={`${idx}`} value={selects}>{reviewPolicyMapping[selects].name}</MenuItem>
            } else if (['department', 'occupation'].includes(field.name)) {
              return <MenuItem key={`${idx}`} value={selects.id}>{selects.name}</MenuItem>
            } else if (field.name === 'appointmentType') {
              return <MenuItem key={`${idx}`} value={selects.id}>{selects.name}</MenuItem>
            } else if (field.name === 'worktimePolicies') {
              return <MenuItem key={`${idx}`} value={selects}>{worktimePolicyMapping[selects].name}</MenuItem>
            } else {
              return <MenuItem key={`${idx}`} value={selects}>{formatMessage({ id: `staff.profile.${field.name}.${selects}` })}</MenuItem>
            }
          })
        }
      </TextField>
    </Grid>
  }

  async function onSaveStaff(userId) {
    setLoadingApprove(true)
    let err = {}
    let newData = staffData

    for (let field of fields) {
      if (field.required && staffData[field.name] === '') {
        err[`${field.name}_err`] = formatMessage({ id: 'form.field.isRequired' })
      }
    }

    if (Object.keys(err).length > 0) {
      newData = { ...staffData, ...err }
    }
    if (Object.keys(err).length > 0) {
      setData(newData)
      return setLoadingApprove(false)
    }

    if ((!currentActive && currentActive !== staffData.active) && users.length >= limits.users) {
      setUserCountErr(true)
      return setLoadingApprove(false)
    }

    const userData = {
      active: staffData.active,
      department: staffData.department,
      reviewLeavePolicy: staffData.reviewLeavePolicy,
      reviewOvertimePolicy: staffData.reviewOvertimePolicy,
      reviewPunchClockPolicy: staffData.reviewPunchClockPolicy,
      worktimeType: staffData.worktimeType,
      displayName: staffData.name,
      email: staffData.email,
      employeeId: staffData.employeeId,
      gender: staffData.gender,
      isManagement: staffData.position === 'management' ? true : false,
      onBoardingDate: dayjs(staffData.onBoardingDate).format('YYYY-MM-DD'),
      appointmentType: staffData.appointmentType && staffData.appointmentType,
      smallStamp: staffData.smallStamp && staffData.smallStamp,
      bigStamp: staffData.bigStamp && staffData.bigStamp,
      certificateStamp: staffData.certificateStamp && staffData.certificateStamp,
      avatarUrl: staffData.avatarUrl,
      occupation: staffData.occupation,
      company: staffData.company,
      unpaidLeave: staffData.unpaidLeave
    }

    if (staffData.worktimeType !== 'shift') {
      userData.worktimePolicies = staffData.worktimePolicies
    }

    if (!staffData.active) {
      userData.endOn = dayjs(staffData.endOn).format('YYYY-MM-DD')
    }

    if (staffData.unpaidLeave.length > 0) {
      userData.unpaidLeave = staffData.unpaidLeave.reduce((acc, cur) => {
        acc.push({
          startDate: dayjs(cur.startDate).format('YYYY-MM-DD'),
          endDate: dayjs(cur.endDate).format('YYYY-MM-DD'),
          note: cur.note
        })
        return acc
      }, [])
    }

    let profileData = {
      phone: staffData.phone,
      address: staffData.address,
      identityCardNumber: staffData.identityCardNumber,
      note: staffData.note,
      emergencyName: staffData.emergencyName,
      emergencyPhone: staffData.emergencyPhone,
      bankAccount: staffData.bankAccount
    }

    if (staffData.fullTime !== '') {
      profileData.fullTime = dayjs(staffData.fullTime).format('YYYY-MM-DD')
    }

    if (staffData.birthDate !== '') {
      profileData.birthDate = dayjs(staffData.birthDate).format('YYYY-MM-DD')
    }

    if (staffData.department !== 'doctor') {
      delete userData.appointmentType
    }

    if (userId === 'new') {
      const newData = {
        email: staffData.email,
        password: Math.random().toString(16),
        displayName: staffData.name,
        disabled: !staffData.active,
      }

      try {
        const res = await callFunction('createAuthentication', { id: userId, ...newData })
        if (res.data) {
          if (res.data.message === 'existingUser') {
            setLoadingApprove(false)
            return setDialogOpen(true)
          }

          try {
            await callFunction('saveUsers', { id: res.data.uid, profile: { ...profileData }, ...userData, action: 'new' })
            setLoadingApprove(false)
            navigate('/staffManagement/staff')
          } catch (ex) {
            setLoadingApprove(false)
            console.log(ex)
          }
        }
      } catch (ex) {
        setLoadingApprove(false)
        console.log(ex)
      }
    } else {
      try {
        await callFunction('saveUsers', { id: userId, profile: { ...profileData }, ...userData, action: 'modify' })
        setLoadingApprove(false)
        navigate('/staffManagement/staff')
      } catch (ex) {
        setLoadingApprove(false)
        console.log(ex)
      }
    }
  }

  if (staffId === 'new' && users.length >= limits.users) {
    return (
      <ApplicationSent
        title={'limit.user.title'}
        message={'limit.user.message'}
        buttonText={'limit.user.buttonText'}
        buttonClick={'/staffManagement/staff'}
        icon={false}
      />
    );
  }

  return (
    <div style={{ backgroundColor: '#f2f2f2', padding: '50px 30px', background: '#fff', display: 'flex', flexDirection: 'column' }}>
      {dialogOpen && <AlertDialog
        dialogTital={formatMessage({ id: 'staff.alert.title.existingUser' })}
        dialogMsg={formatMessage({ id: 'staff.alert.msg.existingUser' })}
        handleClose={() => setDialogOpen(false)}
      />}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative' }}>
          <Divider />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{formatMessage({ id: 'staff.profile.information' })}</Typography>
          </DividerText>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', fontSize: '15px' }}>
            {formatMessage({ id: 'staff.table.inactive' })}
            <Switch
              checked={staffData.active}
              onChange={e => updateData({ name: 'active' }, e.target.checked)}
              color="primary"
              name="active"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            {formatMessage({ id: 'staff.table.active' })}
          </div>
        </Grid>
        <Grid container spacing={1.5} sx={{ display: 'flex', alignItems: 'center' }}>
          {fields.map(field => createField(field))}
          <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '10px' }}>
            <Divider />
            {/* <UnderLine>
              <Typography color="textSecondary" variant="caption">{formatMessage({ id: 'staff.profile.stamps.roots' })}</Typography>
            </UnderLine> */}
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={1} sx={{ fontSize: '13px', color: '#828a99' }}>
            <Grid item xs={12} sm={12} md={3}>
              {formatMessage({ id: 'staff.profile.stamps.profile' })}
              <ImageUpload
                currentImageUrl={staffData.avatarUrl}
                storagePath={`/profileImages/${staffId}`}
                onChange={(url) => updateData({ name: 'avatarUrl' }, url)}
              />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12} sm={12} md={12} sx={{ position: 'relative', marginTop: '50px' }}>
          <Divider />
          <UnderLine>
            <Typography color="textSecondary" variant="caption"></Typography>
          </UnderLine>
        </Grid> */}
        {userCountErr && <Grid item xs={12} sm={12} md={12} >
          <Typography color="red" variant="inherit">{'建立使用者上限'}</Typography>
        </Grid>}
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <ButtonProgress
              handleClose={() => navigate('/staffManagement/staff')}
              handleClick={() => onSaveStaff(staffId ? staffId : 'new')}
              loading={loadingApprove}
              buttonText='button.save'
            />
          </div>
        </Grid>
      </Grid>
    </div >
  );
}

export default EditStaffPage;
