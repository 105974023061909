import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import ScheduleExportDialog from './ScheduleExportDialog';

function FileManagementButton() {
  const theme = useTheme();
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <>
      {open && <ScheduleExportDialog handleClose={() => setOpen(false)}/>}
      <Button
        sx={{ color: '#fff' }}
        onClick={() => setOpen(true)}
      >
        <ImportExportIcon />
        {!isMobile && <FormattedMessage id={'schedule.export.title'} />}
      </Button>
    </>
  )
}

export default FileManagementButton;
