const i18n = {
  'staff': {
    'table': {
      'name': '姓名',
      'active': '啟用',
      'inactive': '停用',
      'department': '部門',
      'occupation': '職稱',
      'email': 'Email',
      'onBoardingDate': '到職日',
      'status': '狀態',
      'header': {
        'displayName': '姓名',
        'email': 'Email',
        'employeeId': '員工編號',
        'status': '狀態'
      }
    },
    'salary': {
      'fullTime': '正職',
      'partTime': '兼職',
      'hourlyPay': '時薪',
      'basicSalary': '底薪',
      // 'fullAttendance': '全勤',
      'subsistenceAllowance': '伙食津貼',
      'jobAllowance': '職務津貼',
      'licenseAllowance': '證照津貼',
      'otherPlus': '其他(+)',
      'insuredDate': '投保日期',
      'insuredAmount': '投保金額',
      'dependents': '眷屬人數',
      'incomeTax': '所得稅',
      'secondGenerationNHI': '二代健保',
      'otherMinus': '其他(-)',
      'note': '備註'
    },
    'profile': {
      'company': '所屬公司',
      'fullTime': '轉正職日期',
      'information': '資訊',
      'unpaidLeave': '留職停薪',
      'startDate': '開始時間',
      'endDate': '結束時間',
      'salaryInformation': '{name} 薪資設定',
      'name': '姓名',
      'onBoardingDate': '到職日',
      'email': 'Email',
      'employeeId': '員工編號',
      'occupation': '職稱',
      'phone': '電話',
      'address': '地址',
      'birthDate': '出生日期',
      'identityCardNumber': '身分證號',
      'emergencyContacts': '緊急聯絡人',
      'emergencyName': '姓名',
      'emergencyPhone': '電話',
      'reviewLeavePolicy': '請假簽核流程',
      'reviewOvertimePolicy': '加班簽核流程',
      'reviewPunchClockPolicy': '忘刷卡簽核流程',
      'worktimeType': {
        'roots': '班表類型',
        'shift': '排班排休制',
        'shiftBased': '排休固定班',
        'fixedShift': '固定班'
      },
      'bankAccount': '銀行帳號',
      'worktimePolicies': '班表規則',
      'weeklyPolicies': '排休規則',
      'note': '備註',
      'department': '部門',
      'gender': {
        'roots': '性別',
        'female': '女',
        'male': '男'
      },
      'onBoardingDateOld': '到職日(舊)',
      'endOn': '離職日期',
      'position': {
        'roots': '位階',
        'management': '主管',
        'employee': '職員',
      },
      'appointmentType': '科別',
      'benefit': {
        'roots': '員工福利點數額度',
        'earned': '年度福利點數',
        'used': '年度已用',
        'balance': '可用總額'
      },
      'stamps': {
        'roots': '圖檔',
        'profile': '頭像'
      }
    },
    'dialog': {
      'title': {
        'add': '新增職員',
        'edit': '編輯職員',
      },
    },
    'alert': {
      'title': {
        'existingUser': 'email已存在',
      },
      'msg': {
        'existingUser': '請確認email是否重複使用?',
      }
    },
    'status': {
      'all': '全部',
      'enabled': '啟用',
      'disabled': '停用',
    },
    'filter': {
      'status': '使用者狀態'
    }
  },
  'selectUserDialog': {
    'title': '選擇使用者',
    'table': {
      'title': '使用者列表'
    }
  }
}

export default i18n
