import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useReactToPrint } from 'react-to-print';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import TabContainer from 'containers/TabContainer/TabContainer';
import CustomerExtTab from './CustomerExtTab';
import EditInformationDialog from './EditInformationDialog';
import EditICEPage from './EditICEPage';
import EditSocialMediaPage from './EditSocialMediaPage';
import EditHistoryPage from './EditHistoryPage';

const Root = styled('div')(({ theme }) => ({
  fontFamily: 'PingFang TC,Roboto,Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  // '& > *': {
  //   // marginTop: theme.spacing(2),
  //   width: '100%',
  // },
}));

const DividerText = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '44px',
  top: '2px',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

function EnhancedDivider({ readOnly, title, editButtonHint = '', onEditBottonClick, printButtonHint = '', onPrintBottonClick }) {
  return (
    <Grid item xs={12} sm={12} md={12} style={{ position: 'relative' }} >
      <Divider sx={{ mt: '8px', mb: '8px' }} />
      <DividerText readOnly={readOnly}>
        <Typography color="textSecondary" variant="caption">
          {title}
        </Typography>
        {!readOnly && <Tooltip title={editButtonHint}>
          <IconButton onClick={onEditBottonClick} size="large">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>}
        {!!onPrintBottonClick && <Tooltip title={printButtonHint}>
          <IconButton onClick={onPrintBottonClick} size="large">
            <PrintIcon fontSize="small" />
          </IconButton>
        </Tooltip>}
      </DividerText>
    </Grid>
  )
}

EnhancedDivider.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  editButtonHint: PropTypes.string,
  onEditBottonClick: PropTypes.func,
  printButtonHint: PropTypes.string,
  onPrintBottonClick: PropTypes.func,
}

function Profile({ customer, userMapping, customerMapping, productMapping, storedValueCard }) {
  const { formatMessage } = useIntl()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [openEditDialog, setOpenEditDialog] = useState(null)
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const extDataMappoing = useSelector(state => state.customerExtTab.data)
  const customerExtTab = Object.keys(config.customerExtTab || {}).map(p => config.customerExtTab[p])
  const customerType = Object.keys(config.customerType || {}).filter(i => config.customerType[i].active).map(i => config.customerType[i])
  const customerTypeMapping = customerType.reduce((acc, cur) => { acc[cur.id] = cur; return acc }, {})
  const componentRef = useRef();

  const formatData = (customer) => {
    const newData = { ...customer }
    newData.salesRepName = userMapping[newData.salesRep] ? userMapping[newData.salesRep].displayName : ''

    return newData
  }

  const customerData = formatData(customer)

  const _fields = [
    { name: 'customerId', sm: 6, md: 2, order: 0 },
    { name: 'name', sm: 6, md: 2, order: 1 },

    { name: 'gender', sm: 6, md: 2, roots: true, order: 3 },
    { name: 'birthDate', sm: 6, md: 2, order: 4 },
    { name: 'phone', sm: 6, md: 2, order: 5 },
    { name: 'identityCardNumber', sm: 6, md: 2, order: 4 },
    { name: 'salesRep', sm: 6, md: 2, order: 7 },
    { name: 'nationality', sm: 6, md: 2, roots: true, order: 8 },
    { name: 'type', sm: 6, md: 2, roots: true, order: 9 },

    { name: 'line', type: '-', sm: 12, md: 12, order: 13 },
    { name: 'maritalStatus', sm: 6, md: 2, roots: true, order: 13 },
    { name: 'occupation', sm: 6, md: 2, order: 14 },
    // { name: 'VISIA', sm: 6, md: 2, order: 15 },

    { name: 'line2', type: '-', sm: 12, md: 12, order: 16 },

    { name: 'address', sm: 12, md: 12, order: 17 },
    { name: 'comment', sm: 12, md: 12, order: 18 },
    { name: 'line3', type: '-', sm: 12, md: 12, order: 98 }
  ]

  for (const c of customerType) {
    if (c.id === customer.type) {
      if (c.requiredItems !== 'none') {
        const data = Object.keys(extDataMappoing[c.requiredItems] || {}).map(i => extDataMappoing[c.requiredItems][i])
        for (const d of data) {
          _fields.push({ ...d, name: d.id, label: d.name, md: 2, sm: 6, order: 10 })
        }
      }

      if (c.items !== 'none') {
        const data = Object.keys(extDataMappoing[c.items] || {}).map(i => extDataMappoing[c.items][i])
        for (const d of data) {
          _fields.push({ ...d, name: d.id, label: d.name, md: 2, sm: 6, order: 11 })
        }
      }
    }
  }


  if (Object.keys(storedValueCard).length > 0) {
    _fields.push({ name: 'storedValueCardLine', text: formatMessage({ id: 'customer.profile.storedValueBalance' }), type: '-', order: 24 })
    let newOrder = 25
    for (const product of Object.keys(storedValueCard)) {
      _fields.push({ name: `${productMapping[product].name}`, type: 'storedValue', sm: 3, value: storedValueCard[product], order: newOrder })
      newOrder += 1
    }
  }

  if (customerData.cantWait) {
    _fields.push({ name: 'cantWait', color: '#ffbf38', type: 'status', sm: 3, order: 99 })
  }
  if (customerData.allergy) {
    _fields.push({ name: 'allergy', color: '#fe2851', type: 'status', sm: 3, order: 99 })
  }
  if (customerData.blackList) {
    _fields.push({ name: 'blackList', color: '#000', type: 'status', sm: 3, order: 99 })
  }
  if (customerData.noPhone) {
    _fields.push({ name: 'noPhone', color: '#a1d938', type: 'status', sm: 3, order: 99 })
  }
  if (customerData.noSms) {
    _fields.push({ name: 'noSms', color: '#2882ff', type: 'status', sm: 3, order: 99 })
  }
  if (customerData.vip) {
    _fields.push({ name: 'vip', color: '#c665c9', type: 'status', sm: 3, order: 99 })
  }

  const fields = _fields.sort((a, b) => {
    return a.order - b.order
  }).map(field => { field.multiline = field.multiline || false; field.md = field.md || 3; return field })

  const printableFields = fields.filter(i => i.order < 24)

  const socialMediaFields = [
    { name: 'email', sm: 3, md: 3, order: 0 },
    { name: 'line', sm: 3, md: 3, order: 1 },
    { name: 'weChat', sm: 3, md: 3, order: 2 },
    { name: 'whatsApp', sm: 3, md: 3, order: 3 },
  ]

  const emergencyContactFields = [
    { name: 'name', type: 'emergencyContact', sm: 3, md: 3, order: 0 },
    { name: 'phoneNumber', type: 'emergencyContact', sm: 3, md: 3, order: 1 },
    { name: 'line', type: '-', sm: 12, md: 12, order: 2 }
  ]

  function createField(field, value, customer) {
    let newValue = customerData[field.name] ? customerData[field.name] : ''
    let labelText = ''

    if (field.name === 'salesRep') {
      newValue = customer.salesRepName
    }



    if (field.type === '-') {
      if (field.text) {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12} sx={{ position: 'relative' }}>
          <Divider sx={{ mt: '8px', mb: '8px' }} />
          <DividerText>
            <Typography color="textSecondary" variant="caption">{field.text}</Typography>
          </DividerText>
        </Grid>
      } else {
        return <Grid item key={field.name} xs={12} sm={field.sm} md={12}></Grid>
      }
    } else if (field.type === 'status') {
      return <Grid item key={field.name} xs={3} sm={field.sm} md={2} sx={{ position: 'relative' }}>
        <Chip sx={{ backgroundColor: field.color, color: '#fff', fontSize: '15px', minWidth: '100px' }} label={formatMessage({ id: `customer.profile.status.${field.name}` })} />
      </Grid>
    } else if (field.type === 'emergencyContact') {
      labelText = formatMessage({ id: `customer.profile.emergencyContacts.${field.name}` })
      newValue = customerData['ICE'][value][field.name]

      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={labelText}
          value={newValue}
          fullWidth
          variant="standard"
          size="small"
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    } else if (field.type === 'storedValue') {
      return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
        <TextField
          multiline={field.multiline}
          type="text"
          label={field.name}
          value={field.value}
          fullWidth
          variant="standard"
          size="small"
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    }

    if (field.name === 'customerId') {
      // const birthDateM = customerData.birthDate ? customerData.birthDate.split('-')[1] : ''
      // const birthDateD = customerData.birthDate ? customerData.birthDate.split('-')[2] : ''
      newValue = customerData.code
    } else if (field.name === 'type') {
      newValue = customerTypeMapping[value] ? customerTypeMapping[value].name : ''
    } else if (['gender', 'maritalStatus', 'nationality', 'infosource'].includes(field.name) && value) {
      if (field.name === 'infosource' &&
        !['advertising',
          'newspapermagazine',
          'officialwebsite',
          'facebook',
          'blog',
          'google',
          'propagandamaterial',
          'line',
          'doctor',
          'instagram'].includes(value)) {
        newValue = `${formatMessage({ id: `customer.profile.${field.name}.other` })}: ${value}`
      } else {
        newValue = formatMessage({ id: `customer.profile.${field.name}.${value}` })
      }
    }

    if (field.type === 'dialog' && field.dialog === 'customers') {
      newValue = customerMapping[newValue]?.name
    }

    if (field.roots) {
      labelText = formatMessage({ id: `customer.profile.${field.name}.roots` })
    } else if (field.label) {
      labelText = field.label
    } else {
      labelText = formatMessage({ id: `customer.profile.${field.name}` })
    }

    return <Grid item key={field.name} xs={12} sm={field.sm} md={field.md}>
      <TextField
        multiline={field.multiline}
        type="text"
        label={labelText}
        value={newValue}
        fullWidth
        variant="standard"
        size="small"
        margin="dense"
        InputProps={{
          readOnly: true,
        }}
      />
    </Grid>
  }

  const tabs = []

  customerExtTab.forEach(tab => {
    if (!tab.related) {
      tabs.push({ label: tab.name, component: <div><CustomerExtTab tab={tab} customerId={customer.id} customerMapping={customerMapping} /></div> })
    }
  })

  let defaultSelect
  const onTabSelected = (tabIndex) => {
    defaultSelect = tabIndex

    if (tabIndex === 0) {
      setActiveTabIndex(0)
    } else if (tabIndex === 1) {
      setActiveTabIndex(1)
    } else if (tabIndex === 2) {
      setActiveTabIndex(2)
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function handleClose() {
    setOpenEditDialog(null)
  }

  return (
    <Root>
      {openEditDialog === 'information' && <EditInformationDialog
        handleClose={() => handleClose()}
        data={customerData}
        customerId={customer.id}
      />}
      {openEditDialog === 'ICE' && <EditICEPage
        handleClose={() => handleClose()}
        data={customerData}
        customerId={customer.id}
      />}
      {openEditDialog === 'socialMedia' && <EditSocialMediaPage
        handleClose={() => handleClose()}
        data={customerData}
        customerId={customer.id}
      />}
      {openEditDialog === 'customerExtData' && <EditHistoryPage
        handleClose={() => handleClose()}
        tab={customerExtTab[`${activeTabIndex}`]}
        customerId={customer.id}
        customerMapping={customerMapping}
      />}
      <Paper sx={{ mt: '20px' }}>
        <div style={{ display:'none' }}>
          <Grid container spacing={2} sx={{ padding: '0 20px 0 20px', mt: '20px' }} ref={componentRef}>
            {printableFields.map(field => createField(field, customerData[field.name], customerData))}
          </Grid>
        </div>

        <Grid container spacing={2} sx={{ padding: '0 20px 0 20px', mt: '20px' }}>
          <EnhancedDivider
            readOnly={!userRights.hasUserRight('customer-edit')}
            title={formatMessage({ id: 'customer.profile.information' })}
            editButtonHint={formatMessage({ id: 'button.edit' })}
            onEditBottonClick={() => setOpenEditDialog('information')}
            printButtonHint={formatMessage({ id: 'button.print' })}
            onPrintBottonClick={handlePrint}
          />
          {fields.map(field => createField(field, customerData[field.name], customerData))}
          <EnhancedDivider
            readOnly={!userRights.hasUserRight('customer-edit')}
            title={formatMessage({ id: 'customer.profile.emergencyContacts.root' })}
            editButtonHint={formatMessage({ id: 'button.edit' })}
            onEditBottonClick={() => setOpenEditDialog('ICE')}
          />
          <>{customerData['ICE'] && Object.keys(customerData['ICE']).map(key => (emergencyContactFields.map(field => createField(field, key, customerData))))}</>
          <EnhancedDivider
            readOnly={!userRights.hasUserRight('customer-edit')}
            title={formatMessage({ id: 'customer.profile.socialMedia' })}
            editButtonHint={formatMessage({ id: 'button.edit' })}
            onEditBottonClick={() => setOpenEditDialog('socialMedia')}
          />
          {socialMediaFields.map(field => createField(field, customerData[field.name], customerData))}
          <EnhancedDivider
            readOnly={!userRights.hasUserRight('customer-edit')}
            title={formatMessage({ id: 'customer.customerExtData.title' })}
            editButtonHint={formatMessage({ id: 'button.edit' })}
            onEditBottonClick={() => setOpenEditDialog('customerExtData')}
          />
          <div style={{ padding: '10px', width: '100%' }}>
            <TabContainer activeTabIndex={0} defaultSelect={defaultSelect} tabs={tabs} onTabSelected={onTabSelected} orien="horizontal" />
          </div>
        </Grid>
      </Paper>
    </Root>
  );
}

Profile.propTypes = {
  customer: PropTypes.object.isRequired,
  userMapping: PropTypes.object,
  customerMapping: PropTypes.object.isRequired,
  productMapping: PropTypes.object.isRequired,
  storedValueCard: PropTypes.object.isRequired
};

export default Profile;
