import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import EnhancedTable from 'components/EnhancedTable';
import SearchBox from 'components/SearchBox';
import FabAdd from 'components/FabAdd';
import ContextStore from 'modules/context';
import { firebaseV8 } from 'modules/firebaseV8';
import EditPolicyDialog from './EditPolicyDialog';
import ReviewPolicyView from './ReviewPolicyView';
import WorktimePolicyView from './WorktimePolicyView';
import WeeklyPolicyView from './WeeklyPolicyView';
import EditSystemPolicy from './EditSystemPolicy';

function PolicyDetail({ policy, type, ...props }) {
  const r = { ...policy }
  if (type === 'review') {
    return (
      <div style={{ padding: 15 }}>
        <ReviewPolicyView reviewPolicy={r} {...props} />
      </div>
    )
  } else if (type === 'worktime') {
    return (
      <div style={{ padding: 15 }}>
        <WorktimePolicyView worktimePolicy={r} {...props} />
      </div>
    )
  } else if (type === 'weekly') {
    return (
      <div>
        <WeeklyPolicyView weeklyPolicy={r} {...props} />
      </div>
    )
  } else {
    return <div></div>
  }
}

PolicyDetail.propTypes = {
  policy: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

function SettingAttendancePage({ type }) {
  const { formatMessage } = useIntl()
  const { setBreadcrumbs, currentUser } = useContext(ContextStore)
  const userRights = useSelector(state => state.userRights)
  const config = useSelector(state => state.config.data)
  const systems = config.systems
  const [openDialog, setOpenDialog] = useState(false)
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const listType = useRef('')
  const filteredPolicies = filterPolicies()
  const filterItems = type !== 'system' ? [
    { name: 'name' },
  ].map(i => { i.text = formatMessage({ id: `setAttendance.${type}Policy.table.detail.${i.name}` }); return i }) : []

  useEffect(() => {
    setBreadcrumbs([{
      text: formatMessage({ id: 'sideMenu.setting.root' })
    }])
    listType.current = ''
    const onSnapshot = snapshot => {
      const policies = []
      snapshot.forEach(doc => {
        policies.push({ ...doc.data(), id: doc.id })
      });
      listType.current = type
      setPolicies(policies)
    }
    const unsubscribe = firebaseV8().collection(`${type}Policies`).onSnapshot(onSnapshot, err => { })
    return () => unsubscribe()
  }, [type]);

  const _headerCells = type !== 'system' ? [
    { text: 'name', align: 'left', sort: 'code', order: 0 },
    { text: 'note', order: 2 },
  ] : []

  const _rowCells = type !== 'system' ? [
    { field: 'name', align: 'left', order: 0 },
    { field: 'note', order: 2 },
  ] : []

  if (type === 'weekly') {
    _headerCells.push({ text: 'year', order: 1 })
    _rowCells.push({ field: 'year', order: 1 })
  }

  const headerCells = _headerCells
    .map(c => { c.text = formatMessage({ id: `setAttendance.${type}Policy.table.header.${c.text}` }); return c })
    .sort((a, b) => a.order - b.order)
  const rowCells = _rowCells.sort((a, b) => a.order - b.order)

  const onEditClick = async (policy) => {
    const newData = { ...policy }
    // newData.steps = newData.steps.map((i, idx) => ({ ...i, id: idx }));
    setSelectedPolicy(newData)
  }

  function filterPolicies() {
    if (!currentFilter) {
      return policies
    }
    let items = [...policies]
    items = items.filter(s => s[currentFilter.name].toLowerCase().includes(currentFilter.text.toLowerCase()))
    return items
  }

  function getNewPolicySetting() {
    if (type === 'review') {
      return { id: 'new', steps: [] }
    } else if (type === 'worktime') {
      return {
        id: 'new',
        worktime: {
          Sun: { startTime: null, endTime: null },
          Mon: { startTime: '09:00', endTime: '18:00', maxHours: 8 },
          Tue: { startTime: '09:00', endTime: '18:00', maxHours: 8 },
          Wed: { startTime: '09:00', endTime: '18:00', maxHours: 8 },
          Thu: { startTime: '09:00', endTime: '18:00', maxHours: 8 },
          Fri: { startTime: '09:00', endTime: '18:00', maxHours: 8 },
          Sat: { startTime: null, endTime: null },
        }
      }
    } else {
      return {
        id: 'new',
        earned: {
          Jan: 0,
          Feb: 0,
          Mar: 0,
          Apr: 0,
          May: 0,
          Jun: 0,
          Jul: 0,
          Aug: 0,
          Sep: 0,
          Oct: 0,
          Nov: 0,
          Dec: 0,
        }
      }
    }
  }

  const onFilterChanged = (name, text) => {
    if (text !== '') {
      setCurrentFilter({ name, text })
    } else {
      setCurrentFilter(null)
    }
  }

  if (type === 'system') {
    return <Box sx={{ marginTop: '20px' }}>
      {openDialog && <EditSystemPolicy
        handleClose={() => setOpenDialog(false)}
        currentUser={currentUser}
      />}
      {userRights.hasUserRight('setting-edit') &&
        <FabAdd onClick={() => setOpenDialog(true)} icon='edit' />
      }
      <Grid container sx={{ mt: 2 }} spacing={1}>
        <Grid item xs={3} sm={3} md={3}>
          <TextField
            type="text"
            label={formatMessage({ id: `setAttendance.${type}Policy.table.annualLeaveType.root` })}
            value={systems ? formatMessage({ id: `setAttendance.${type}Policy.table.annualLeaveType.${systems.annualLeaveType}` }) : ''}
            fullWidth
            variant="standard"
            size="small"
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      {selectedPolicy &&
        <EditPolicyDialog
          policy={selectedPolicy}
          handleClose={() => setSelectedPolicy(null)}
          type={type}
        />
      }
      {userRights.hasUserRight('setting-edit') &&
        <FabAdd onClick={() => setSelectedPolicy(getNewPolicySetting())} />
      }
      <Toolbar sx={{ pl: 2, pr: 1 }}>
        <Typography sx={{ flex: '1 1 100%', flexShrink: 2 }} variant="h6" id="tableTitle" component="div" />
        <SearchBox filterItems={filterItems} onFilterChanged={onFilterChanged} updateUrlParams />
      </Toolbar>
      <EnhancedTable
        defaultOrder="asc"
        defaultOrderField="code"
        headerCells={headerCells}
        rowCells={rowCells}
        getExpandContent={policy =>
          <PolicyDetail
            policy={policy}
            type={type === listType.current ? type : ''}
            onEditClick={onEditClick}
            handleEditClick={() => onEditClick(policy)}
          />
        }
        tableData={filteredPolicies}
      />
    </Box>
  );
}

SettingAttendancePage.propTypes = {
  type: PropTypes.string.isRequired
};

export default SettingAttendancePage;
