import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { styled } from '@mui/material/styles';
import IconButtonMui from '@mui/material/IconButton'

import ContextStore from 'modules/context';
import { getIcon } from 'enum/iconModules';
import { useModuleMapping } from 'hooks/modules';

const iconColor = '#1976d2';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  height: '100%',
  width: '100%',
}))

const IconTable = styled('div')(() => ({
  marginLeft: '1rem',
  marginRight: '1rem',
  position: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '0 5%',
  display: 'flex',
  justifyContent: 'center'
}))

const Icon = styled('div')(() => ({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '200px',
  height: '206px',
  margin: '20px 0 10px 0',
  fontSize: '18px',
  fontWeight: 600,
  textAlign: 'center',
  color: '#666'
}));

const IconButton = styled(IconButtonMui)(() => ({
  width: '85px',
  height: '85px',
  backgroundColor: '#fff',
  color: iconColor,
  '&:hover': {
    backgroundColor: iconColor,
    color: '#fff',
    '@media (hover: none)': {
      color: iconColor,
    }
  },
  '& svg': {
    width: '55px',
    height: '55px',
    borderRadius: '120px',
    // padding: '20px',
    overflow: 'visible'
  }
}));

function Home({ userRights }) {
  const { setBreadcrumbs } = useContext(ContextStore)
  const navigate = useNavigate()
  const location = useLocation()
  const { formatMessage } = useIntl()
  const moduleMapping = useModuleMapping()
  const config = useSelector(state => state.config.data)
  const homePageMapping = Object.keys(config.homePage || {}).reduce((acc, cur) => {
    if (config.homePage[cur].subItem !== 'none') {
      acc[`${config.homePage[cur].item}.${config.homePage[cur].subItem}`] = { ...config.homePage[cur] }
    } else {
      acc[config.homePage[cur].item] = { ...config.homePage[cur] }
    }
    return acc
  }, {})

  const menuItems = filterByModules(filterByUserRights([
    {
      text: 'appointments', icon: getIcon('appointments'), path: 'appointments', userRight: 'appointment-view', modules: ['appointment'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'appointments.schedule', path: 'schedule' },
        { text: 'appointments.list', path: 'list' },
        { text: 'appointments.editList', path: 'editList' },
      ]))
    },
    {
      text: 'customers', icon: getIcon('customers'), path: 'customers', modules: ['customer'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'customers.list', path: 'list', userRight: 'customer-view' },
      ]))
    },
    { text: 'schedule', icon: getIcon('schedule'), path: 'schedule/normal', modules: ['schedule'] },
    {
      text: 'staffManagement', icon: getIcon('staff'), path: 'staffManagement', subMenuItems: filterByModules(filterByUserRights([
        { text: 'staffManagement.staff', path: 'staff', userRight: 'user-view' },
        { text: 'staffManagement.punchClockException', path: 'punchClockException', userRight: 'schedule-exception', modules: ['schedule'] },
      ]))
    },
    {
      text: 'leaveOvertimeWork', icon: getIcon('leaveOvertime'), path: 'leaveOvertimeWork', subMenuItems: filterByModules(filterByUserRights([
        { text: 'leaveOvertimeWork.owner', path: 'leaves', userRight: 'leaveOvertime-view', modules: ['leaveOvertime', 'punchClock'] },
        { text: 'leaveOvertimeWork.review', path: 'review', userRight: 'any-review', modules: ['leaveOvertime', 'punchClock'] },
        { text: 'leaveOvertimeWork.overview', path: 'overview', userRight: 'any-overview', modules: ['leaveOvertime', 'punchClock'] },
      ]))
    },
    {
      text: 'products', icon: getIcon('product'), path: 'products', modules: ['product'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'products.list', path: 'list', userRight: 'product-view', modules: ['product'] },
      ]))
    },
    {
      text: 'sales', icon: getIcon('sales'), path: 'sales', modules: ['sales'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'sales.salesOrders', path: 'salesOrders', userRight: 'salesOrder-view' },
        { text: 'sales.refund', path: 'refund', userRight: 'refund-view' },
      ]))
    },
    {
      text: 'services', icon: getIcon('services'), path: 'services', modules: ['service'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'services.consume', path: 'consume', userRight: 'consumeService-view' },
        { text: 'services.transfer', path: 'transfer', userRight: 'transferService-view' },
        { text: 'services.transform', path: 'transform', userRight: 'transformService-view' },
      ]))
    },
    { text: 'finance', icon: getIcon('finance'), path: 'finance', userRight: 'finance-view', modules: ['finance'] },
    {
      text: 'report', icon: getIcon('report'), path: 'report', subMenuItems: filterByModules(filterByUserRights([
        { text: 'report.sales', path: 'sales/day', userRight: 'report-view', modules: ['report'] },
      ]))
    },
    {
      text: 'announcement', icon: getIcon('announcement'), path: 'announcement', modules: ['announcement'] , simplify: true, subMenuItems: filterByModules(filterByUserRights([
        { text: 'announcement.list', path: 'list', userRight: 'announcement-view' },
      ]))
    },
    {
      text: 'purchase', icon: getIcon('purchase'), path: 'purchase', modules: ['purchase', 'warehouse'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'purchase.requisition', path: 'requisition/pending', userRight: 'requisition-view' },
        { text: 'purchase.purchaseOrder', path: 'purchaseOrder/pending', userRight: 'purchaseOrder-view' },
        { text: 'purchase.receipt', path: 'receipt/pending', userRight: 'receipt-view' },
        { text: 'purchase.returnForm', path: 'returnForm', userRight: 'returnForm-view', modules: ['warehouse'] },
      ]))
    },
    {
      text: 'delivery', icon: getIcon('delivery'), path: 'delivery', modules: ['warehouse'], subMenuItems: filterByModules(filterByUserRights([
        { text: 'delivery.deliveryOrder', path: 'deliveryOrder', userRight: 'deliveryOrder-view' },
        { text: 'delivery.salesReturnForm', path: 'salesReturnForm/pending', userRight: 'deliveryOrder-view' },
      ]))
    },
    {
      text: 'stock', icon: getIcon('stock'), path: 'stock', modules: ['warehouse'], subMenuItems: filterByUserRights([
        { text: 'stock.query', path: 'query', userRight: 'stock-query' },
        // { text: 'stock.arrangeStockForm', path: 'arrangeStockForm', userRight: 'whInventory-view' },
        { text: 'stock.stockRequisition', path: 'stockRequisition/pending', userRight: 'stockRequisition-view' },
        { text: 'stock.scrapForm', path: 'scrapForm/pending', userRight: 'scrapForm-view' },
        { text: 'stock.inventoryChange', path: 'inventoryChange/pending', userRight: 'inventoryChange-view' },
        { text: 'stock.inventoryCheck', path: 'inventoryCheck/processing', userRight: 'inventoryCheck-view' },
      ])
    },
    { text: 'vendor', icon: getIcon('vendor'), path: 'vendor', userRight: 'vendor-view' },
    { text: 'merchandise', icon: getIcon('merchandise'), path: 'merchandise', userRight: 'merchandise-view' },
    {
      text: 'setting', icon: getIcon('setting'), path: 'setting', subMenuItems: filterByModules(filterByUserRights([
        { text: 'setting.appointment', path: 'appointment/treatments', userRight: 'setting-edit', modules: ['appointment'] },
        { text: 'setting.customer', path: 'customer/extData', userRight: 'setting-edit', modules: ['customer'] },
        { text: 'setting.staff', path: 'staff/departments', userRight: 'setting-edit', modules: ['user'] },
        { text: 'setting.attendance', path: 'attendance/reviewPolicy', userRight: 'setting-edit', modules: ['schedule', 'leaveOvertime', 'punchClock'] },
        { text: 'setting.salesOrder', path: 'salesOrder', userRight: 'setting-edit', modules: ['sales'] },
        { text: 'setting.merchandise', path: 'merchandise/category', userRight: 'setting-edit', modules: ['merchandise'] },
        { text: 'setting.report', path: 'report/reportCategory', userRight: 'setting-edit', modules: ['report'] },
        { text: 'setting.home', path: 'home', },
        { text: 'setting.userRight', path: 'userRight', userRight: 'userRights-edit' },
      ]))
    },
  ]));

  function filterByModules(items) {
    return items.filter(i => (!i.modules && (!i.subMenuItems || (i.subMenuItems && i.subMenuItems.length))) || i.modules.some(m => moduleMapping[m]))
  }

  function filterByUserRights(items) {
    return items.filter(i => (!i.userRight && (!i.subMenuItems || (i.subMenuItems && i.subMenuItems.length))) || userRights.hasUserRight(i.userRight))
  };

  useEffect(() => {
    setBreadcrumbs([{
      text: '首頁'
    }])
    return () => {
    };
  }, [location.pathname]);

  const getMenuItem = ({ text, icon, path, subMenuItems }, index) => {
    return !subMenuItems ? homePageMapping[text].show && <Icon key={text}>
      <IconButton
        onClick={() => navigate(path)}
        size="large">
        {icon}
      </IconButton>
      {formatMessage({ id: `homePage.${text}` })}
    </Icon> : subMenuItems.map(({ text, path: subPath }) => (
      homePageMapping[text]?.show && <Icon key={text}>
        <IconButton
          onClick={() => navigate(`${homePageMapping[text].item}/${subPath}`)}
          size="large">
          {icon}
        </IconButton>

        {
          ['setting'].includes(homePageMapping[text].item) ?
            formatMessage({ id: `homePage.${text}` }) + formatMessage({ id: `homePage.${homePageMapping[text].item}.root` }) :
            formatMessage({ id: `homePage.${text}` })
        }
      </Icon>
    ))
  }

  return (
    <Root>
      <IconTable>
        {menuItems.map((item, index) => getMenuItem(item, index))}
      </IconTable>
    </Root>
  );
}

Home.propTypes = {
  userRights: PropTypes.object.isRequired,
};

export default Home;
